<template>
    <v-container>
        <!-- ========================================================== -->
        <v-card>
            <v-card-title class="blue darken-2 pa-3">
                <h3 class="white--text">設定</h3>
            </v-card-title>
            <v-card-text>
                <v-btn to="/favorites" color="success" block class="my-16 pa-9">
                    <h2>お気に入り設定</h2>
                </v-btn>
                <v-btn to="/chart-setting" color="success" block class="my-16 pa-9">
                    <h2>グラフ設定</h2>
                </v-btn>
                <v-btn to="/lineworks-notify" color="success" block class="my-16 pa-9 text-capitalize2" v-show="dispLineworksNotify">
                    <h2>Line Works通知設定</h2>
                </v-btn>
                <div class="my-16">
                    <v-btn :href="lineLink()" color="success" block class="pa-9" v-show="isLineUser()">
                        <h2>LINEアプリへ</h2>
                    </v-btn>
                    <h5 v-show="false" class="red--text">(LineNotifyは、2025年3月31日に終了します。)</h5>
                </div>
                <div class="my-16">
                    <v-btn to="/line-notify" color="success" block class="pa-9" v-show="dispLineNotify">
                        <h2>Line通知設定</h2>
                    </v-btn>
                    <h5 v-show="false" class="red--text">(LineNotifyは、2025年3月31日に終了します。)</h5>
                </div>
                <v-btn to="/manual" color="success" block class="my-16 pa-9">
                    <h2>動画マニュアル</h2>
                </v-btn>
                <v-btn @click="linkToOtherWindow('https://ise-hp.com/')" color="success" block class="my-16 pa-9" v-show="false">
                    <h2>アイエスイー</h2>
                </v-btn>
                <v-btn to="/logout" color="warning" block class="my-16 pa-9">
                    <h2>ログアウト</h2>
                </v-btn>
                <!--
                -->
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "Settings",
    components: {
        // colormap,
    },
    //#######################################################
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSites: [],
            dbUsers: [],
            dbUser: {},
            dispLineNotify: false,
            dispLineworksNotify: false,
            flowData: [
                { x: 0, y: 0, u: 1.5, v: 0.2 },
                { x: 1, y: 1, u: 0.3, v: 0.7 },
                { x: 3, y: 2, u: 0.8, v: 0.5 },
                { x: 6, y: 3, u: 3.8, v: 0.9 },
                // 他のデータを追加...
            ],
        };
    },
    //#######################################################
    created: async function () {
        //====================================================
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //---------------------------
        //this.getSites();
        this.getUsers();
        //---------------------------
        window.scrollTo(0, 0);
    },
    //#######################################################
    methods: {
        //====================================================
        isLineUser() {
            if (this.dbUser.line_link) return true;
            else return false;
        },
        //====================================================
        lineLink() {
            const lineUrl = this.dbUser.line_link;
            return lineUrl;
        },
        //====================================================
        linkToOtherWindow(url) {
            window.open(url, "_blank");
        },
        //====================================================
        async getSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                //params: { "_order[sort_no]": "desc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbSites = response.data.json;
            });
        },
        //====================================================
        async getUsers() {
            await this.axios({
                method: "GET",
                url: "/web/api/users",
                //params: { "_order[sort_no]": "desc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbUsers = response.data.json;
                if (this.dbUsers[0]) {
                    this.dbUser = this.dbUsers[0];
                    if (this.dbUsers[0].line_notify_token) this.dispLineNotify = true;
                    if (this.dbUsers[0].lineworks_board) this.dispLineworksNotify = true;
                }
            });
        },
        //====================================================
    },
    //#######################################################
};
</script>
