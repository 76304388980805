<template>
    <v-container>
        <v-overlay :value="isLoading" absolute>
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ============================================= -->
        <v-menu v-model="dispCalender" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined dense v-model="targetDate" readonly v-bind="attrs" v-on="on" background-color="white"></v-text-field>
            </template>
            <v-date-picker v-model="targetDate" @input="dispCalender = false" locale="ja" :max="maxDate" @change="axoisGetDataList()"></v-date-picker>
        </v-menu>
        <!-- ============================================= -->
        <v-card elevation="24" class="mb-3">
            <v-toolbar color="indigo" dark>
                <v-toolbar-title class="font-weight-bold">{{ dbDevice.name }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-0">
                <div class="white chart-container" style="position: relative; width: 100%; height: auto; overflow: auto">
                    <!--
                    <comp-chart-otdr :chartDataChild="chartDataSetOtdr" :yRange="chartRange.otdr" :width="1200" />
                    -->
                    <comp-chart-winch-detail class="pa-5" style="height: 45vh; width: 1200px" :chartDataChild="chartDataSet" :chartOptions="chartOptions" />
                </div>
            </v-card-text>
        </v-card>
        <!-- ============================================= -->
        <v-divider></v-divider>
        <v-card elevation="24">
            <h3 class="text-center">{{ dispCursorTime() }}</h3>
            <v-slider :min="0" :max="86400 - 1" v-model="cursor_time" dense hide-details></v-slider>
            <div class="pa-1">
                <timeline :imageList="imageList"></timeline>
            </div>
        </v-card>
        <v-divider></v-divider>
        <v-card class="text-center py-3">
            <v-btn class="ml-1" color="info" @click="slideChange(-600)"><v-icon>mdi-step-backward-2</v-icon></v-btn>
            <v-btn class="ml-1" color="info" @click="slideChange(-1)"><v-icon>mdi-step-backward</v-icon></v-btn>
            <!--
            <v-btn class="ml-1" :color="isPlay ? 'error' : 'info'" @click="slidePlay()"><v-icon>mdi-play-pause</v-icon></v-btn>
            -->
            <v-btn class="ml-1" color="info" @click="slideChange(1)"><v-icon>mdi-step-forward</v-icon></v-btn>
            <v-btn class="ml-1" color="info" @click="slideChange(600)"><v-icon>mdi-step-forward-2</v-icon></v-btn>
        </v-card>
        <!-- ============================================= -->
    </v-container>
</template>

<script>
import Timeline from "@/components/CompTimeline";
//import CompChartOtdr from "@/components/CompChartOtdr.vue";
import CompChartWinchDetail from "@/components/CompChartWinchDetail.vue";
//import { Buffer } from "buffer";

export default {
    name: "WinchDetail",
    components: {
        Timeline,
        CompChartWinchDetail,
    },
    //##################################################################################
    data() {
        return {
            jwt: "",
            myInfo: [],
            device_id: 0,
            dbDevice: { id: 0, name: "" },
            cursor_time: 0,
            imageList: [],
            dispCalender: false,
            targetDate: this.$moment().format("YYYY-MM-DD"),
            maxDate: this.$moment().format("YYYY-MM-DD"),
            isPlay: false,
            isLoading: false,
            winchData: "",
            dispChart: false,
            //---------------------------------
            chartDataSet: { datasets: [] },
            chartOptions: { maintainAspectRatio: false },
            //---------------------------------
            chartColor: {
                deep: "#0000cd",
                water: "#7fd1ea", //"#87ceeb", // skyblue
                oxygen: "#ff9e25", //"#ffa500" //orange
                salinity: "#808080", //"#808080", //gray
                chlorophyll: "#87f098", //"#90ee90", // lightgreen
                turbidity: "#aa1529", //"#a52a2a", // brown
                fsi: "#aa1529", //"#a52a2a", // brown
            },
            chartRange: {
                deep: { min: -10, max: 0 },
                water: { min: 0, max: 30 },
                oxygen: { min: 0, max: 10 },
                salinity: { min: 0, max: 40 },
                chlorophyll: { min: 0, max: 10 },
                turbidity: { min: 0, max: 10 },
                fsi: { min: 0, max: 3 },
            },
            //---------------------------------
        };
    },
    //##################################################################################
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //-----------------------
        this.device_id = this.$route.params.device_id;
        this.localRangeUpdate();
        await this.axiosGetDevice();
        await this.axoisGetDataList();
        //-----------------------
        window.scrollTo(0, 0);
        this.dispChart = true;
        await new Promise((r) => setTimeout(r, 3000));
        //-----------------------
        //-----------------------
    },
    //##################################################################################
    beforeDestroy: function () {},
    //##################################################################################
    mounted: function () {},
    //##################################################################################
    watch: {
        $route: async function (to, from) {
            if (from.fullPath !== to.fullPath) {
                this.device_id = to.params.device_id;
                this.axiosGetDevice();
                await this.axoisGetDataList();
            }
        },
    },
    //##################################################################################
    methods: {
        //====================================================
        async localRangeUpdate() {
            if (this.$localStorage.get("chart.range.json") != undefined) {
                let localRange = JSON.parse(this.$localStorage.get("chart.range.json"));
                if (Number(localRange["deep"]["min"])) this.chartRange.deep.min = Number(localRange["deep"]["min"]);
                if (Number(localRange["deep"]["max"])) this.chartRange.deep.max = Number(localRange["deep"]["max"]);
                if (Number(localRange["water"]["min"])) this.chartRange.water.min = Number(localRange["water"]["min"]);
                if (Number(localRange["water"]["max"])) this.chartRange.water.max = Number(localRange["water"]["max"]);
                if (Number(localRange["salinity"]["min"])) this.chartRange.salinity.min = Number(localRange["salinity"]["min"]);
                if (Number(localRange["salinity"]["max"])) this.chartRange.salinity.max = Number(localRange["salinity"]["max"]);
                if (Number(localRange["oxygen"]["min"])) this.chartRange.oxygen.min = Number(localRange["oxygen"]["min"]);
                if (Number(localRange["oxygen"]["max"])) this.chartRange.oxygen.max = Number(localRange["oxygen"]["max"]);
                if (Number(localRange["chlorophyll"]["min"])) this.chartRange.chlorophyll.min = Number(localRange["chlorophyll"]["min"]);
                if (Number(localRange["chlorophyll"]["max"])) this.chartRange.chlorophyll.max = Number(localRange["chlorophyll"]["max"]);
                if (Number(localRange["turbidity"]["min"])) this.chartRange.turbidity.min = Number(localRange["turbidity"]["min"]);
                if (Number(localRange["turbidity"]["max"])) this.chartRange.turbidity.max = Number(localRange["turbidity"]["max"]);
                if (Number(localRange["fsi"]["min"])) this.chartRange.fsi.min = Number(localRange["fsi"]["min"]);
                if (Number(localRange["fsi"]["max"])) this.chartRange.fsi.max = Number(localRange["fsi"]["max"]);
            }
        },
        //====================================================
        async axiosGetDevice() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: {
                    id: this.device_id,
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDevice = response.data.json[0];
            });
        },
        //====================================================
        slidePlay: async function () {
            if (this.isPlay == true) {
                this.isPlay = false;
            } else {
                this.isPlay = true;
                while (this.isPlay) {
                    await this.slideChange(+1);
                    await new Promise((r) => setTimeout(r, 300));
                    if (this.cursor_time > 86400 - 100) this.isPlay = false;
                }
            }
        },
        //====================================================
        slideChange: async function (seconds) {
            let nextCursor = Number(this.cursor_time) + Number(seconds);
            while (0 < nextCursor && nextCursor < 86400) {
                if (this.imageList[nextCursor]) break;
                if (seconds == 0) break;
                if (seconds > 0) nextCursor++;
                else nextCursor--;
            }
            if (nextCursor < 0) {
                this.registWinchChartData();
                return true;
            }
            if (nextCursor > 86400 - 1) {
                this.registWinchChartData();
                return true;
            }
            this.cursor_time = nextCursor;
            this.axiosGetData();
            //await new Promise(r => setTimeout(r, 5000));
        },
        //====================================================
        dispCursorTime: function () {
            const hh = (this.cursor_time / 3600) | 0; // 小数点切り捨て
            const mm = ((this.cursor_time - hh * 3600) / 60) | 0; // 小数点切り捨て
            const ss = this.cursor_time % 60; // 余り
            return ("00" + hh).slice(-2) + ":" + ("00" + mm).slice(-2) + ":" + ("00" + ss).slice(-2);
        },
        //====================================================
        async axoisGetDataList() {
            this.isLoading = true;
            this.chartDataSetOtdr = { datasets: [] };
            //await new Promise((r) => setTimeout(r, 1000));
            await this.axios({
                method: "GET",
                url: "/web/api/winch-list/" + this.device_id + "/" + this.targetDate,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            })
                .then((response) => {
                    this.imageList = new Array(86400).fill("");
                    let dateAry = response.data.json;
                    for (let i in dateAry) {
                        let hh = this.$moment(dateAry[i]).hours();
                        let mm = this.$moment(dateAry[i]).minute();
                        let ss = this.$moment(dateAry[i]).second();
                        let aryNo = hh * 3600 + mm * 60 + ss;
                        this.imageList[aryNo] = dateAry[i];
                    }
                    this.isLoading = false;
                    this.isPlay = false;
                    this.cursor_time = 86400;
                    this.slideChange(-1);
                })
                .catch((err) => {
                    this.isLoading = false;
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
        async axiosGetData() {
            //------------------------------------------------
            const winchFileName = this.imageList[this.cursor_time];
            if (!winchFileName) return false;
            this.winchData = null;
            //------------------------------------------------
            this.isLoading = true;
            await new Promise((r) => setTimeout(r, 1000));
            //------------------------------------------------
            await this.axios({
                //responseType: "blob",
                method: "GET",
                url: "/web/api/winch/" + this.device_id + "/" + winchFileName,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            })
                .then((response) => {
                    this.isLoading = false;
                    this.winchData = response.data;
                    this.registWinchChartData();
                })
                .catch((err) => {
                    this.isLoading = false;
                    if (err.response) {
                        const { status, statusText } = err.response;
                        alert(status + " : " + statusText);
                    } else {
                        alert(err);
                    }
                });
            //----------------------------------------------------
            this.isLoading = false;
            //----------------------------------------------------
        },
        //====================================================
        registWinchChartData() {
            this.chartDataSet = {};
            let tmpDS = [];
            let winchDeep = [];
            let winchWaterTemp = [];
            let winchChlorophyll = [];
            let winchTurbidity = [];
            let winchSalinity = [];
            let winchOxygen = [];
            let winchFsi = [];
            let winchStatus = [];
            //----------------------
            this.tableItems = [];
            //----------------------
            for (let i in this.winchData) {
                if (undefined != this.winchData[i].deep) winchDeep.push({ x: i * 1, y: this.winchData[i].deep != null ? this.winchData[i].deep * -1 : null });
                if (undefined != this.winchData[i].water_temp) winchWaterTemp.push({ x: i * 1, y: this.winchData[i].water_temp });
                if (undefined != this.winchData[i].chlorophyll) winchChlorophyll.push({ x: i * 1, y: this.winchData[i].chlorophyll });
                if (undefined != this.winchData[i].turbidity) winchTurbidity.push({ x: i * 1, y: this.winchData[i].turbidity });
                if (undefined != this.winchData[i].salinity) winchSalinity.push({ x: i * 1, y: this.winchData[i].salinity });
                if (undefined != this.winchData[i].oxygen) winchOxygen.push({ x: i * 1, y: this.winchData[i].oxygen });
                if (undefined != this.winchData[i].fsi) winchFsi.push({ x: i * 1, y: this.winchData[i].fsi });
                if (undefined != this.winchData[i].winch) winchStatus.push({ x: i * 1, y: ["down", "up"].includes(this.winchData[i].winch) ? 1 : 0 });
                //-------------------
                // テーブル表示用測定値
                this.tableItems.push({
                    date: this.winchData[i].date,
                    deep: this.winchData[i].deep,
                    water_temp: this.winchData[i].water_temp,
                    oxygen: this.winchData[i].oxygen,
                    salinity: this.winchData[i].salinity,
                    chlorophyll: this.winchData[i].chlorophyll,
                    turbidity: this.winchData[i].turbidity,
                    ad_vals: this.winchData[i].ad_vals,
                    magnet_vals: this.winchData[i].magnet_vals,
                    winch: this.winchData[i].winch,
                });
                //-------------------
            }
            if (winchDeep.length > 0)
                tmpDS.push({
                    label: "水深",
                    yAxisID: "axis-water-deep",
                    data: winchDeep,
                    borderColor: this.chartColor.deep, //"darkgreen",
                    borderWidth: 1,
                    pointRadius: 0,
                    fill: false,
                });
            if (winchWaterTemp.length > 0)
                tmpDS.push({
                    label: "水温",
                    yAxisID: "axis-water-temp",
                    data: winchWaterTemp,
                    borderColor: this.chartColor.water, //"darkgreen",
                    borderWidth: 1,
                    pointRadius: 0,
                    fill: false,
                });
            if (winchChlorophyll.length > 0)
                tmpDS.push({
                    label: "クロロ",
                    yAxisID: "axis-chlorophyll",
                    data: winchChlorophyll,
                    borderColor: this.chartColor.chlorophyll, // "lightgreen",
                    borderWidth: 1,
                    pointRadius: 0,
                    fill: false,
                });
            if (winchTurbidity.length > 0)
                tmpDS.push({
                    label: "濁度",
                    yAxisID: "axis-turbidity",
                    data: winchTurbidity,
                    borderColor: this.chartColor.turbidity, // "brown",
                    borderWidth: 1,
                    pointRadius: 0,
                    fill: false,
                });
            if (winchSalinity.length > 0)
                tmpDS.push({
                    label: "塩分",
                    yAxisID: "axis-salinity",
                    data: winchSalinity,
                    borderColor: this.chartColor.salinity, // "gray",
                    borderWidth: 1,
                    pointRadius: 0,
                    fill: false,
                });
            if (winchOxygen.length > 0)
                tmpDS.push({
                    label: "DO",
                    yAxisID: "axis-oxygen",
                    data: winchOxygen,
                    borderColor: this.chartColor.oxygen, // "orange",
                    borderWidth: 1,
                    pointRadius: 0,
                    fill: false,
                });
            if (winchFsi.length > 0)
                tmpDS.push({
                    label: "有害プランクトン",
                    yAxisID: "axis-fsi",
                    data: winchFsi,
                    borderColor: this.chartColor.fsi, // "orange",
                    borderWidth: 1,
                    pointRadius: 0,
                    fill: false,
                });
            if (winchStatus.length > 0)
                tmpDS.push({
                    label: "ウインチ稼働",
                    yAxisID: "axis-winch-status",
                    data: winchStatus,
                    borderColor: "gold",
                    backgroundColor: "rgba(255, 255, 0, 0.05)",
                    borderWidth: 1,
                    pointRadius: 0,
                    lineTension: 0.5,
                    fill: true,
                });
            this.chartDataSet = JSON.parse(JSON.stringify({ datasets: tmpDS }));
            //====================================================
            let yAxes = [];
            if (winchDeep.length > 0)
                yAxes.push({
                    id: "axis-water-deep",
                    display: true,
                    position: "left",
                    scaleLabel: { display: true, labelString: "水深(m)", fontColor: "black" },
                    ticks: {
                        min: Number(this.chartRange.deep.min),
                        max: Number(this.chartRange.deep.max),
                        fontColor: this.chartColor.deep,
                    },
                });
            if (winchWaterTemp.length > 0)
                yAxes.push({
                    id: "axis-water-temp",
                    display: true,
                    position: "left",
                    scaleLabel: { display: true, labelString: "水温(℃)", fontColor: "black" },
                    ticks: {
                        min: Number(this.chartRange.water.min),
                        max: Number(this.chartRange.water.max),
                        fontColor: this.chartColor.water,
                    },
                });
            if (winchOxygen.length > 0)
                yAxes.push({
                    id: "axis-oxygen",
                    display: true,
                    position: "left",
                    scaleLabel: { display: true, labelString: "DO(mg/L)", fontColor: "black" },
                    ticks: {
                        min: Number(this.chartRange.oxygen.min),
                        max: Number(this.chartRange.oxygen.max),
                        fontColor: this.chartColor.oxygen,
                    },
                });
            if (winchSalinity.length > 0)
                yAxes.push({
                    id: "axis-salinity",
                    display: true,
                    position: "left",
                    scaleLabel: { display: true, labelString: "塩分", fontColor: "black" },
                    ticks: {
                        min: Number(this.chartRange.salinity.min),
                        max: Number(this.chartRange.salinity.max),
                        fontColor: this.chartColor.salinity,
                    },
                });
            if (winchChlorophyll.length > 0)
                yAxes.push({
                    id: "axis-chlorophyll",
                    display: true,
                    position: "left",
                    scaleLabel: { display: true, labelString: "クロロフィル(ppb)ウラニン基準", fontColor: "black" },
                    ticks: {
                        min: Number(this.chartRange.chlorophyll.min),
                        max: Number(this.chartRange.chlorophyll.max),
                        fontColor: this.chartColor.chlorophyll,
                    },
                });
            if (winchTurbidity.length > 0)
                yAxes.push({
                    id: "axis-turbidity",
                    display: true,
                    position: "left",
                    scaleLabel: { display: true, labelString: "濁度(FTU)ホルマジン基準", fontColor: "black" },
                    ticks: {
                        min: Number(this.chartRange.turbidity.min),
                        max: Number(this.chartRange.turbidity.max),
                        fontColor: this.chartColor.turbidity,
                    },
                });
            if (winchFsi.length > 0)
                yAxes.push({
                    id: "axis-fsi",
                    display: true,
                    position: "left",
                    scaleLabel: { display: true, labelString: "有害プランクトン", fontColor: "black" },
                    ticks: {
                        min: Number(this.chartRange.fsi.min),
                        max: Number(this.chartRange.fsi.max),
                        fontColor: this.chartColor.fsi,
                    },
                });
            if (winchStatus.length > 0)
                yAxes.push({
                    display: false,
                    id: "axis-winch-status",
                    ticks: { min: 0, max: 1 },
                });
            ///------------------------------------------------
            this.chartOptions = {
                maintainAspectRatio: false,
                legend: { display: true, position: "bottom" },
                scales: {
                    xAxes: [
                        {
                            type: "linear",
                            ticks: {
                                callback: function (value) {
                                    return value + "秒";
                                },
                            },
                        },
                    ],
                    yAxes: yAxes,
                },
                tooltips: { enabled: true },
                elements: {
                    line: { tension: 0 },
                    point: { radius: 1, hitRadius: 10, hoverRadius: 1, hoverBorderWidth: 3 },
                    //point: false
                },
                animation: false,
            };
        },
        //====================================================
    },
};
</script>
